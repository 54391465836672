.mainSectionContainer {
  margin-top: 50px;
  margin-left: 0px;
  /* width: 80%; */
  /* margin-left: 5%; */
}

.sectionSubtitle {
  font-family: Barlow;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #bed5c6;
  margin-bottom: 25px;
}


.mainCardsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}

.cardItemContainer {
  height: 128px;
  border-radius: 12px 12px 0px 0px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 0px 10px 0px;
}

.textsContainer {
  border-radius: 0px 0px 12px 12px;
  gap: 10px;
  padding: 10px 20px 10px 20px;
  height: 110px;
}

.cardItemContainerGrey {
  background-color: #bbc2c7;
}

.cardItemContainerGreen {
  background-color: #bed5c6;
}

.cardItemContainerWhite {
  background-color: #eceeef;
}

.textsContainerGrey {
  background-color: #d4d8db;
}

.textsContainerGreen {
  background-color: #dfeae3;
}

.textsContainerWhite {
  background-color: #ffffff;
}

.textsContainer h6 {
  font-family: Raleway;
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #1a2228;
}

.textsContainer p {
  font-family: Barlow;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #425563;
}

.chevronIconContainer {
  width: 62px;
  height: 34px;
  padding: 9px 24px 9px 24px;
  border-radius: 20px;
  gap: 10px;
  background-color: #2e3c46;
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomContentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}



@media (min-width: 768px) {}

@media (min-width: 1024px) {
  .mainSectionContainer{
    margin: 50px auto 0;
    max-width: 1225px;
    width: 100%;
  }
  .mainCardsContainer {
    flex-direction: row;
    gap: 32px;
  }
  .toolItemContainer {
    flex: 1;
  }

  .textsContainer {
    padding: 20px 20px 10px 20px;
  }

}