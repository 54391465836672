.container {
    position: relative;
    user-select:none;
  }
  
  .rPassword-container {
    position: absolute; /* o 'fixed' según tus necesidades */
    top: 50%; /* Posiciona el borde superior del elemento en la mitad de la pantalla */
    left: 50%; /* Posiciona el borde izquierdo del elemento en la mitad de la pantalla */
    transform: translate(-50%, -50%); /* Centra el elemento horizontal y verticalmente */
    width: 320px;
    padding: 20px 30px;
    
    border-radius: 12px;
    background-color: #2E3C46;
    gap: 24px;
    user-select:none;
  }
  
  .txt-pass-rpass {
    width: 100%;
    height: 50px;
    background-color: #5E6F7A;
    color: #BBC2C7;
    font-family: Barlow;
    font-size: 15.19px;
    padding-left: 0.5em;
    border-radius: 4px 4px 0px 0px;
  }
  
  .txt-pass-rpass {
    margin-bottom: 8px;
  }
  
  .txt-pass-rpass::placeholder {
    color: #BBC2C7;
    font-family: Barlow;
    font-size: 15.19px;
    padding-left: 0.5em;
    font-style: normal;
    font-weight: 400;
    line-height: 21.266px;
    font-feature-settings: 'clig' off, 'liga' off;
  }
  
  .rPassword-title {
    font-family: Raleway;
    font-size: 21.62px;
    color: #ECEEEF;
    font-weight: 700;
    margin-bottom: 24px;
    user-select:none;
  }

  .rPassword-description {
    font-family: Raleway;
    color: #ECEEEF;
    user-select: none;
  }
  
  .rPassword-btn {
    width: 100%;
    height: 46px;
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 24px;
    background: #FF8166;
  }
  
  .rPassword-btn-text {
    color: #1A2228;
    font-size: 15.19px;
    font-weight: 600;
    margin-top: 24px;
  }
  
  .rPassword-image {
    margin-bottom: 24px;
    position: relative; 
    margin-left: -0.3em;
  }
  
  .txt-recover-password {
    color: #FFCABF;
    font-family: Barlow;
    font-size: 15.19px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.266px;
    margin-top: 8px;
    margin-bottom: 24px;
  }
  
  .normal-text {
    color: #ECEEEF;
    font-family: Barlow;
    font-size: 15.19px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.266px;
    margin-right: 8px;
  }
  
  .password-input-container {
    position: relative;
    display: inline-block;
  }
  
  .toggle-password-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.1em;
    width: 18px;
    height: 16px;
    cursor: pointer;
    color: #ECEEEF;
  }
  
  @media (min-width: 768px) {

    .rPassword-container {
      padding: 32px 40px;
      width: 440px;
    }

  }
