.app__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button {
  
  all: unset; 
  box-sizing: border-box; 
  display: inline-block; 
  cursor: pointer; 
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

body input[type="text"],
body input[type="password"],
body textarea {
  border-width: 0;
}
