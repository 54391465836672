.cardServiceItemContainer {
  
  padding: 10px 20px 20px ;
  border-radius: 12px;
  gap: 20px;
  background-color: #2e3c46;
}

.mainBottom {
  margin-bottom: 10%;
}

.servicesCardsContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
}

.cardServiceItemContainer h4 {
  font-family: Raleway;
  font-size: 17px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #eceeef;
  margin-bottom: 20px;
}

.optionCheckContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.cardServiceItemContainer p {
  font-family: Barlow;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #eceeef;
  margin: 0;
  margin-left: 8px;
}

.papperIcon {
  margin-left: -20px;
  margin-bottom: -12px;
}

.seeDetailsLink p {
  font-size: 15px;
  font-weight: 900;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffcabf;
  text-decoration: none;
  cursor: pointer;
  margin-top: 25px;
}

.seeDetailsLink {
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
}


@media (min-width: 1024px) {
  .servicesCardsContainer{
    flex-direction: row;
  }
  .cardServiceItemContainer {
    flex: 1;
  }
  .cardServiceItemContainer h4{
    margin-bottom: 30px;
  }
  .optionCheckContainer{
    margin-bottom: 15px;
  }
}