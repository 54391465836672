.card-body {
  margin-left: 15px;
}

.btn {
  font-weight: bold;
  size: 17px;
}

.collapse:not(.show) {
  width: 1250px !important;
}

.acordeonTabs {
  width: 1250px !important;
}

.card {
  max-width: 100% !important;
  background-color: #cedfd5;
  color: black;
  width: auto !important;
  margin-bottom: 15px;
}

.card-false {
  max-width: 100% !important;
  background-color: rgba(46, 60, 70, 0.94);
  color: white;
  width: auto !important;
  margin-bottom: 15px;
  border-radius: 0.375rem;
}

.card-header-false {
  max-width: 100% !important;
  background-color: rgba(46, 60, 70, 0.94);
  border-bottom: none !important;
  padding: 0.5rem;

  h5 span {
    color: white !important;
    margin-left: 9px;
  }
}

.card-header {
  max-width: 100% !important;
  background-color: #cedfd5;
  color: black;
  border-bottom: none !important;
}

.termsAndServicesMainContainer {
  flex-direction: row;
  justify-content: space-between;
  max-width: 1216px;
  margin: 20px auto 0;
  padding-bottom: 20px;
}

.terminosServicio-information__principal-title span.terminosServicio-information__principal_span:first-child {
  text-align: center;
  color: var(--gray-100, #eceeef);
  font-family: Barlow;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin: 0 0 10px;
  display: block;
  text-align: left;
}

.terminosServicio-information__principal-title p {
  color: var(--gray-200, #d4d8db);
  font-family: Barlow;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 140% */
}
.termsAndServicesMainContainer img {
  max-width: 200px;
  height: auto;
}

.container-news-sgg {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}

.content-news-sgg {
  display: flex;
  max-width: auto;
  margin-top: 1em;
}

.main {
  flex-grow: 1;
  flex-basis: auto;
  width: 46.5625em;
  margin-right: 1.2em;
}

.suggestions {
  flex-shrink: 0;
  flex-basis: auto;
  width: 25.4375em;
}

.dark-overlay:before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 8888;
}

.activate-overlay {
  position: relative;
  z-index: 9999;
}

.terminosServicioContainer {
  margin-bottom: 51px;
  max-width: 1216px;
  margin: 0 auto;
  padding: 0 0 20px;
}

.summaryContainer {
  background-color: #cedfd5;
  padding: 20px 50px 20px 50px;
  border-radius: 12px;
  margin-bottom: 32px;
  width: 98%;
  margin-left: 13px;
}

.summaryDescription {
  background-color: #cedfd5;
  padding: 12px 20px 12px 20px;
  border-radius: 12px;
  margin: 20px 0; 
}

.summaryText {
  font-family: Barlow;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  margin: 0;
  padding: 5px 0 ;
  text-align: left;
}

.descriptionText {
  color: var(--gray-200, #d4d8db);
  font-family: Barlow;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.clientsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
  width: 100%;
}
.clientIconItem {
  background-color: #eceeef;
  display: flex;
  /* max-width: 275px; */
  height: 108px;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-right: 0;
  margin-bottom: 0;
  border-radius: 12px;
  width: 100%;
}

.clientsContainer {
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1216px;
  margin: 0 auto;

}

.container {
  display: flex;
  flex-direction: row
}

.container {
  display: flex;
  flex-direction: row
}

.container {
  display: flex;
  flex-direction: row
}

.imageContainer {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
}

.summaryAboutUsContainer {
  display: flex;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  max-width: 1216px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .termsAndServicesMainContainer{
    display: flex;
  }
}

@media (min-width: 1024px) {  
  
  .terminosServicio-information__principal-title span:first-child,
  .terminosServicio-information__principal-title span.terminosServicio-information__principal_span:first-child{
    font-size: 34px !important;
    line-height: 48px !important;
  }
  .termsAndServicesMainContainer img{
    max-width: inherit;
  }

  .terminosServicio-information__principal-title p,
  .termsAndServicesMainContainer_text {
      font-size: 17px !important;
      line-height: 23px !important;
      margin: 49px 0 0;
  }
  
  .descriptionText {
    font-size: 17.09px;
    line-height: 23.926px;
  }
  
  .summaryDescription p{
    font-size: 19px ;
    line-height: 27px ;
  }
  .clientIconItem{
    max-width: 275px;
  }
  .clientsContainer{
    flex-direction: row;
    gap: 30px;
    margin-top: 30px;
  }
  .termsAndServicesMainContainer{
    margin-top: 49px;
  }
  .terminosServicioContainer {
      padding: 0px 0 53px;
  }
  
}

@media (min-width: 1440px) {
}